<template transition="scale-transition" origin="center center">
  <v-container id="status_offers_container" fluid fill-height>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <DataTableOffers
          ref="OffersTable"
          :headers="headers"
          name="Offers"
          :title="$t('components.views.trip.offers.transport_company.table.offers')"
          :url="url"
          actionsOn
          filtersOn
          :searchP="searchField"
          :height="650"
          :urlCount="urlCount"
          updateOn
          id="status_offers_table_container"
        >

        <template #[`item.information.tripNumber`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on" class="text-wrap text-column">
                {{ item.information.tripNumber }}
              </span>
            </template>
            <span class="text-column color-tooltip" >
              {{ item.information.tripNumber }}
            </span>
          </v-tooltip>
        </template>

        <template #[`item.information.numberOrder`]="{ item }">
            <template>
              <span v-on="on" class="text-wrap limit-text">
                {{ item.information.numberOrder }}
              </span>
            </template>
        </template>

          <template #[`item.status.amount`]="{ item }">
            {{ formatMoney(item.status.amount, item.currency) }}
          </template>

          <template #[`item.status`]="{ item }">
            <p v-if="item.status.type == 8">
              {{ $t("components.views.trip.offers.transport_company.table.under_negotiation") }}
            </p>
          </template>

          <template #[`item.origin.schedule_date`]="{ item }">
            {{
              item.information?item.origin.schedule_date: ""
            }}
          </template>

          <template #[`item.destiny.schedule_date`]="{ item }">
            {{
              item.information?item.destiny.schedule_date: ""
            }}
          </template>

          <template #[`item.date_publish`]="{ item }">
            {{ item.information.createdOn | formatDate }}
          </template>

          <template #[`item.time_publish`]="{ item }">
            {{ timeElpased(item.information.createdOn) }}
          </template>

          <template #[`item.options`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-if="typeUser === 2"
                  color="primary"
                  :disabled="item.status.type === 8"
                  v-on="on"
                  @click="onOpenInfoOffer(item)"
                >
                  <v-icon>fa-solid fa-circle-info</v-icon>
                </v-btn>
              </template>
              <span>{{
                $t("components.views.trip.offers.transport_company.card_transport_company.detail")
              }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-if="typeUser === 2"
                  color="red darken-2"
                  v-on="on"
                  :disabled="item.status.type === 8"
                  @click="onOpenModalFormOffer(item)"
                >
                  <v-icon>fa-solid fa-check</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("components.views.trip.offers.transport_company.table.accept") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  v-if="typeUser === 2"
                  color="red darken-2"
                  :disabled="item.status.type === 8"
                  v-on="on"
                  @click="onCancelFormOffer(item)"
                >
                  <v-icon>fa-solid fa-xmark</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("components.views.trip.offers.transport_company.table.reject") }}</span>
            </v-tooltip>
          </template>
        </DataTableOffers>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogFormOffer" width="600px" scrollable persistent>
      <ModalFormAccept
        @closeForm="onCloseModalFormOffer"
        @reloadPage="onRealoadTable"
        :idMonitoringOrder="idMonitoringOrder"
        :idCompanyTransporter="idCompanyTransporter"
        :value="amount"
        :key="index"
      />
    </v-dialog>
    <v-dialog v-model="dialogFormCounterOffer" width="600px" scrollable persistent>
      <ModalFormCounterOffer
        @closeForm="onCloseModalFormOffer"
        @reloadPage="onRealoadTable"
        :idCompanyTransporter="idCompanyTransporter"
        :idMonitoringOrder="idMonitoringOrder"
        :value="amount"
        :key="index"
      />
    </v-dialog>
    <v-dialog v-model="dialogInfoOffer" width="900px" persistent>
      <ModalDetails
        @closeForm="onCloseInfoOffer"
        :idMonitoringOrder="idMonitoringOrder"
        :type="type"
        :key="index"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialogInfoOffer: false,
      idMonitoringOrder: 0,
      idCompanyTransporter: 0,
      index: 0,
      amount: 0,
      dialogFormOffer: false,
      dialogFormCounterOffer: false,
      type: 0,
      on: null,
    };
  },
  components: {
    ModalFormAccept: () =>
      import(
        /* webpackChunkName: "ModalFormAccept" */ "@/components/views/Trip/Offers/TransportCompany/Modals/ModalFormAccept/ModalFormAccept.vue"
      ),
    ModalFormCounterOffer: () =>
      import(
        /* webpackChunkName: "ModalFormCounterOffer" */ "@/components/views/Trip/Offers/TransportCompany/Modals/ModalFormCounterOffer/ModalFormCounterOffer.vue"
      ),
    ModalDetails: () =>
      import(
        /* webpackChunkName: "ModalDetails" */ "@/components/views/Trip/Offers/TransportCompany/Modals/ModalDetails/ModalDetails.vue"
      ),
  },
  name: "Offers",
  computed: {
    ...mapGetters({
      user: "auth/user",
      search: "search/search",
    }),
    idCompany() {
      let userMap = JSON.parse(this.user);
      return userMap.user.idCompany;
    },
    typeUser() {
      let userMap = JSON.parse(this.user);
      return userMap.user.typeUser;
    },
    url() {
      return `/Offers`;
    },
    urlCount() {
      return `/Offers/ListCount`;
    },
    searchField() {
      return this.search;
    },
    headers() {
      return [
        {
          text: this.$t("components.views.trip.offers.transport_company.table.trip_no"),
          class: "text-center",
          align: "center",
          value: "information.tripNumber",
          urlFilter: "Offers/GetTripNumbers",
          vmodel: "TripNumber",
          name: "TripNumber",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.client.table.order_number"),
          class: "text-center",
          align: "center",
          value: "information.numberOrder",
          urlFilter: "Offers/GetNumberOrders",
          vmodel: "NumberOrder",
          name: "NumberOrder",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.amount"),
          class: "text-center",
          align: "center",
          value: "status.amount",
          urlFilter: "Offers/GetAmounts",
          vmodel: "Amount",
          name: "Amount",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.type_operation"),
          class: "text-center",
          align: "center",
          value: "information.nameTypeOperation",
          urlFilter: "Offers/GetTypesOperation",
          vmodel: "TypeOperations",
          name: "TypeOperations",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.origin_city"),
          class: "text-center",
          align: "center",
          value: "information.origin.city",
          urlFilter: "Offers/GetOriginCities",
          vmodel: "OriginCities",
          name: "OriginCities",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.origin_address"),
          class: "text-center",
          align: "center",
          value: "information.origin.address",
          urlFilter: "Offers/GetOriginAddresses",
          vmodel: "OriginAddresses",
          name: "OriginAddresses",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.origin_date"),
          class: "text-center",
          align: "center",
          value: "information.origin.schedule_date",
          urlFilter: "Offers/GetOriginDates",
          vmodel: "OriginDates",
          name: "OriginDates",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.destiny_city"),
          class: "text-center",
          align: "center",
          value: "information.destiny.city",
          urlFilter: "Offers/GetDestinationCities",
          vmodel: "DestinationCities",
          name: "DestinationCities",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.destiny_address"),
          class: "text-center",
          align: "center",
          value: "information.destiny.address",
          urlFilter: "Offers/GetDestinationAddresses",
          vmodel: "DestinationAddresses",
          name: "DestinationAddresses",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.destiny_date"),
          class: "text-center",
          align: "center",
          value: "information.destiny.schedule_date",
          urlFilter: "Offers/GetDestinationDates",
          vmodel: "DestinationDates",
          name: "DestinationDates",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.date_publish"),
          class: "text-center",
          align: "center",
          value: "date_publish",
          urlFilter: "Offers/GetPublicationDates",
          vmodel: "PublicationDates",
          name: "PublicationDates",
          showFilter: false,
        },
        {
          text: this.$t("components.views.trip.offers.transport_company.table.time_publish"),
          class: "text-center",
          align: "center",
          value: "time_publish",
          urlFilter: "",
          vmodel: "OffersTimePublished",
          name: "OffersTimePublished",
          showFilter: false,
        },
      ];
    },
  },
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.offered"),
    };
  },
  watch: {
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
    searchField(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.onRealoadTable();
        });
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    onRealoadTable() {
      this.dialogFormCounterOffer = false;
      this.dialogFormOffer = false;
      this.$refs.OffersTable.loadData();
      this.$emit("reloadPage");
    },

    async onOpenModalFormOffer(item) {
      this.$nextTick(() => {
        this.index += 1;
        this.idMonitoringOrder = item.idMonitoringOrder;
        this.idCompanyTransporter = item.information.idCompany;
        this.amount = item.status.amount;
      });

      if (
        await this.$root.$confirm(
          this.$t("components.views.trip.offers.transport_company.table.title_counteroffer"),
          this.$t("components.views.trip.offers.transport_company.table.description_counteroffer"),
          "si"
        )
      ) {
        this.dialogFormCounterOffer = true;
      } else {
        this.dialogFormOffer = true;
      }

      this.index += 1;
    },

    async onCancelFormOffer(item) {
      this.$nextTick(() => {
        this.idMonitoringOrder = item.idMonitoringOrder;
        this.idCompanyTransporter = item.information.idCompany;
      });

      if (
        await this.$root.$confirm(
          this.$t("components.views.trip.offers.transport_company.table.reject_title"),
          this.$t("components.views.trip.offers.transport_company.table.reject_description"),
          "aceptar"
        )
      ) {
        this.showLoader({
          show: true,
        });

        let params = {
          idMonitoringOrder: this.idMonitoringOrder,
          idTransporter: this.idCompany,
        };

        await this.axios
          .post("Offers/RejectTransportCompany", params)
          .then(() => {
            this.showSnack({
              text: this.$t("components.views.trip.offers.transport_company.table.success_reject"),
              title: this.$t("components.views.trip.offers.transport_company.table.success"),
              name: "success",
            });
          })
          .catch((error) => {
            this.showSnack({
              text: error.response.data,
              title: "Error",
              name: "error",
            });
          })
          .finally(() => {
            this.showLoader({
              show: false,
            });
            this.onRealoadTable();
          });
      }
    },

    async onCloseModalFormOffer() {
      if (
        await this.$root.$confirm(
          this.$t("components.views.trip.offers.transport_company.table.cancel_title"),
          this.$t("components.views.trip.offers.transport_company.table.cancel_description"),
          "aceptar"
        )
      ) {
        this.dialogFormOffer = false;
        this.dialogFormCounterOffer = false;
      }
    },

    timeElpased(CreatedOn) {
      let now = new Date();
      let countFrom = new Date(CreatedOn).getTime();
      let timeDifference = now - countFrom;
      let secondsInADay = 60 * 60 * 1000 * 24;
      let secondsInAHour = 60 * 60 * 1000;
      let days = Math.floor((timeDifference / secondsInADay) * 1);
      let years = Math.floor(days / 365);

      if (years > 1) {
        days = days - years * 365;
      }

      let hours = Math.floor(((timeDifference % secondsInADay) / secondsInAHour) * 1);
      let mins = Math.floor(
        (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) * 1
      );

      let hour =
        hours > 0
          ? `${this.$t(
              "components.views.trip.offers.client.card_transport_company.and"
            )} ${hours} ${this.$t(
              "components.views.trip.offers.client.card_transport_company.hours"
            )}`
          : "";

      return `${days} ${this.$t(
        "components.views.trip.offers.client.card_transport_company.days"
      )} ${hour}`;
    },

    onOpenInfoOffer(item) {
      this.$nextTick(() => {
        this.index += 1;
        this.idMonitoringOrder = item.idMonitoringOrder;
        this.idCompanyTransporter = item.information.idCompany;
        this.dialogInfoOffer = true;
      });
    },
    onCloseInfoOffer() {
      this.dialogInfoOffer = false;
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './TableStyle.scss';
</style>

